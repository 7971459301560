
export const animateValue = (refEl, start, end) => {
    let el = refEl.getElementsByTagName('span')[0]
    let value = start
    let increaseAmt
    const endValue = parseInt(end)
    let countInt
    if(endValue < 16){
        increaseAmt = parseInt(1)
    } else{
        increaseAmt = parseInt(end/15)
    }
    
   

    const count = () => {
        value = value+increaseAmt
         el.innerHTML = `${value}`
        if(value >= endValue){
            clearInterval(countInt)
            el.innerHTML = `${endValue}`
        }
    }
    countInt = setInterval(count, 40)
}

export const setValueAttr = (id, value) => {
    let el = document.getElementById(id)
    el.setAttribute("value", value)
}