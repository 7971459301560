import React, { useRef, useEffect, useCallback } from "react"
import * as styles from './DataEm.module.scss'
import useIntersection from "../../../../hooks/useIntersection";
import { animateValue } from "../../../../util/utils";


const DataEm = ({ total, title, type='number', bg='light', size='med', stackText=false, underline=false}) => {
    // type: number, percentage
    const dataRef = useRef(null);
    const animate = useRef({
        value: true 
    }) 

    let displayAfter = {
        'number': '',
        'percentage': '%',
        'plus': '+'
    }

    let inViewport = useIntersection(dataRef, '1')
    
    

    const animateData = useCallback(() => { 
            animateValue(dataRef?.current, 0, total)
    },[ dataRef, total])
 
    
    useEffect(() => {
        if (inViewport) {
            if(animate.current.value){
                animateData()
                animate.current.value=false
            }
            if(underline){
                dataRef.current.classList.add(styles.draw)
            }
        }
    }, [dataRef, animateData, inViewport, underline])

    return(  
           <div ref={dataRef} className={`${styles.DataEm} ${styles[`${size}`]} ${styles[`${bg}`]} ${stackText ? styles.stackText : ''} ${underline ? styles.underline : ''} data_em` }>
               <data value={total}>
                    <div><span>0</span>{displayAfter[type]} </div>
                    <em>{title}</em>
                </data>
           </div>
    )
}

export default DataEm