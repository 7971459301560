// extracted by mini-css-extract-plugin
export var DataEm = "DataEm-module--DataEm--180d4";
export var dark = "DataEm-module--dark--0774f";
export var displayLg = "DataEm-module--displayLg--3d715";
export var displayMed = "DataEm-module--displayMed--7e76e";
export var draw = "DataEm-module--draw--8ad7b";
export var lg = "DataEm-module--lg--f74b7";
export var light = "DataEm-module--light--92546";
export var med = "DataEm-module--med--36ab3";
export var sm = "DataEm-module--sm--a6290";
export var stackText = "DataEm-module--stackText--4d3f7";
export var underline = "DataEm-module--underline--5c300";