import React from 'react'
import'./WorldMap.scss'
import WorldMapSVG from './../../../../images/maps/world.inline.svg'


const WorldMap = ({color}) => {
    return(
        <section className={`worldmap ${color}`}>
            <WorldMapSVG />
        </section>
    )
}


export default WorldMap