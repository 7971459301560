import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'
import WorldMap from '../components/UI/Images/WorldMap/WorldMap'
import DataEm from '../components/UI/Data/DataEm/DataEm'
import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/IntlProgramSupport.inline.svg'

const ProgramSupportPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'International',
    title2: 'Program Support',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Navigating <span>Every Step</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                {' '}
                With experts located domestically and abroad, we assist
                customers to navigate every step of Foreign Military Sales (FMS)
                and Cooperative Agreement processes. We support more than 35
                nations to help them understand the complexities of policies,
                laws, databases, and organizations affecting international
                security assistance and security cooperation.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/j2gONjHC6ac?yt:cc=on"
            title="Precise Program Support Video"
          />
        </SectionContainer>

        {/* Countries Supported Map */}
        <SectionContainer
          id="countriesSupported"
          color="gray"
          type="fullWidth"
          classes={['inlineChildren_quarter']}
        >
          <DataEm
            total="35"
            title="Countries Supported"
            size="displayLg"
            stackText="true"
            underline="true"
          />
          <WorldMap color="blue" />
        </SectionContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="International Program Support"
            ServiceIcon={ServiceIcon}
            hexSize="small"
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default ProgramSupportPage
